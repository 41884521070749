//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-412:_7504,_4604,_7608,_9576,_9516,_3040,_8704,_5064;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-412')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-412', "_7504,_4604,_7608,_9576,_9516,_3040,_8704,_5064");
        }
      }catch (ex) {
        console.error(ex);
      }